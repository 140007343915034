.rightBarContent{
    padding: 25px;
    width: 80%;
}

.trends{
    background-color: #222b34;
    border-radius: 25px;
    min-height: calc(100vh - 200px);
    max-width: 290px;
    margin-top: 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
}



.trend:hover{
    cursor: pointer;
    background-color: #2c353e;
}



.tag-text{
    margin-top: 5px;
}



.tags-info{
    display: flex;
    flex-direction: row;

}

.text-for-tag{
    width: 110px;
    height: 25px;
    margin-right: 3px;
}

.drop-for-day{
    width: 110px;
    height: 35px;
    border-radius: 13px;
    background-color: #222b34;
    color: white;
    margin-right: 3px;
}

.contained{
    width: 100px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 15px;
    padding: 10px;
    height: 15px;
    background-color: green;
    
}