.title{
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.verify-account{
    color: white;
}

.description{
    padding-top: 10%;
    width: 650px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.col{
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    
}

.col-1{
    padding-left: 5%;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;  
}

.row{
    padding-right: 5%;
    display: flex;
    justify-content: center;
    flex-direction: row;  
}

.text-1{
    color: white;
    position: relative;
    top: -40px;
    left: 40px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    padding-left: 15%;
}

.text-2{
    color: white;
    position: relative;
    top: -40px;
    left: 40px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    padding-left: 10%;
}

.make-blue-print{
    color: black;
    position: relative;
    background-color: aliceblue;
    font-size: 16px;
    font-weight: bold;
    border: 0.5px solid white;
    width: 100px;
    justify-content: center;
    display: flex;
    padding: 10px 10px;
    margin-left: 20%;
    border-radius: 1000px;
}

.make-golden-print{
    color: black;
    position: relative;
    background-color: aliceblue;
    font-size: 16px;
    font-weight: bold;
    border: 0.5px solid white;
    width: 100px;
    justify-content: center;
    display: flex;
    padding: 10px 10px;
    margin-left: 20%;
    border-radius: 1000px;
}

.text{
    color: white;
    position: relative;
    top: -40px;
    left: 40px;
    font-size: 16px;
    font-weight: 500;
}